import request from "@/utils/request";

// ApprovalOrder
export function approvalOrderList(params) {
  return request({ url: `/approval_orders/`, method: "get", params });
}

export function approvalOrderCancel(data) {
  return request({ url: `/approval_orders/${data.id}/cancel/`, method: "post", data });
}

// ApprovalTask
export function approvalTaskList(params) {
  return request({ url: `/approval_tasks/`, method: "get", params });
}

export function approvalTaskApprove(data) {
  return request({ url: `/approval_tasks/${data.id}/approve/`, method: "post", data });
}

export function approvalTaskReject(data) {
  return request({ url: `/approval_tasks/${data.id}/reject/`, method: "post", data });
}

// ApprovalRecord
export function approvalRecordList(params) {
  return request({ url: `/approval_records/`, method: "get", params });
}
